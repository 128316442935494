body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.padding-component {
  padding: 8px 8px;
}

.m-t-5px {
  margin-top: 5px;
}

.tableFixedWidth {
    width:125px;
}

.preshow .button:hover {
    font-size: 18px;
    background: #bc9049;
    color: #fff !important;
    border: 1px solid #bc9049;
}

.course-show .margin-zero-table td{
    font-size:12px;
}