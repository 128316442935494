body {
    min-height: 100vh;
    background-color: #F5F5F5 !important;
}

.login-form {
    margin-top:50px;
}

.login-form h1{
    font-size:30px;
    font-weight:300;
}

main{
    width:100%;
}

.login-background {
    background: url('images/tlo.png') no-repeat;
    background-size:100%;
    width:100%;
    height:100vh;
}

.logo {
    background: url('images/logo.png');
    background-size: 100%;
    width: 294px;
    height: 79px;
    margin-top:50px;
    display:block;
}

.logo-small {
    background: url('images/logo.png');
    background-size: 100%;
    width: 294px;
    height: 79px;
    margin-left: 50px;
}

.MuiTextField-root {
    height: 53px;
    border: 1px solid #BB8D49 !important;
    border-radius: 5px;
    padding:4px;
}

.MuiInput-underline:before, .MuiInput-underline:after {
    border-bottom: none !important;
}

.filter .MuiInput-underline:before, .filter .MuiInput-underline:after {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.MuiInputBase-root {
    margin-left:5px;
}

.MuiFormLabel-root {
    margin-left: 5px;
    margin-top: -5px
}

.MuiInputLabel-shrink {
    margin-top: 4px
}

br {
    min-height: 20px;
}

.space {
    min-height:20px;
}

.login-form a {
    color: #B9B9B9;
}



button.MuiButton-textPrimary {
    background: #BB8D49 0% 0% no-repeat padding-box;
    border-radius: 5px !important;
    box-shadow: 0px 4px 10px #00000026;
    color: #fff !important;
    height: 53px;
}

.MuiButton-label {
    color: #fff !important;
    
}

.gold-font {
    color: #BB8D49 !important;
    float:none !important;
    font-size:14px;
}

.blue-font {
    color: #BB8D49 !important;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    display: block;
    margin: 0 auto;
    text-align: center;
}

.blue-font2 {
    color: #BB8D49 !important;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    margin: 0 auto;
    float: left;
    margin-top: 55px;
    margin-left: 30px;
}

.gray-font {
    color: #bc9049 !important;
    font-size: 22px;
    text-decoration: none;
    font-weight: 400;
    display: block;
    margin: 0 auto;
    text-align: center;
}

.brown-font {
    color: #bc9049 !important;
    font-size:16px;
}

.gray-color {
    color: #B9B9B9 !important;
}

.gray-color-2 {
    float:left;
    color: #B9B9B9 !important;
}

.gray-font2 {
    color: #B9B9B9 !important;
    font-size: 16px;
    text-decoration: none;
    font-weight: 400;
    display: block;
    margin: 0 auto;
    text-align: center;
    float: left;
    margin-top: 55px;
    margin-left:30px;
}

.top {
    background: #F5F5F5;
    box-shadow:none;
}

.MuiPaper-elevation4 {
    box-shadow:none !important;
}

.menu {
    margin-left:50px;
    list-style:none;
}

.menu li {
    float: left;
    margin-left: 20px;
}

.MuiAppBar-colorPrimary {
    background:none;
}

header {
    margin-top: 24px;
}

.avatar-form {
    max-width: 80%;
    height: auto;
}

.avatar {
    position:absolute;
    right:200px;
}

.avatar img {
    display: block;
    width: 33px;
    height: 33px;
    float: left;
    border-radius:50%;
}

.avatar .av {
    background: url('images/avatar.png') no-repeat;
    display:block;
    width:33px;
    height:33px;
    float:left;
}

.avatar p{
    float:left;
    color:#000;
    font-size:12px;
    line-height: 0px;
    height: 0px;
    margin-top: 8px;
}

.gray-small {
    color: #B9B9B9 !important;
    font-size:12px !important;
}

.description-avatar p {
    width:200px;
}

.description-avatar {
    font-size: 12px;
    margin-left: 10px;
    float: left;
    width: 200px;
    text-decoration:none;
}

.i {
    background: url('images/i.png') no-repeat top left;
    background-size: 100%;
    width: 8px;
    height: 21px;
    float: left;
}

.logout {
    background: url('images/logout.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.playlist {
    background: url('images/playlist.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.back {
    background: url('images/back.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.create {
    background: url('images/create.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.smile {
    background: url('images/pass.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float:right;
    right:20px;
}

.download {
    background: url('images/download.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.idetails {
    background: url('images/details.png') no-repeat left 12px;
    background-size: 100%;
    width: 21px;
    height: 25px;
    display:inline-block;
}

.notsmile {
    background: url('images/notpass.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    right: 20px;
    float:right;
}

.show {
    background: url('images/visibility.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.remove {
    background: url('images/delete_outline.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.library {
    background: url('images/library.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.filter-icon {
    background: url('images/filter.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.search {
    background: url('images/search.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.block {
    background: url('images/remove_circle.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.check {
    background: url('images/check.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
}

.swap_vert {
    background: url('images/swap_vert.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 15px;
    margin-right: 10px;
    display: table-cell;
    padding-top: 15px;
}

.done {
    background: url('images/done.png') no-repeat top left;
    background-size: 100%;
    width: 21px;
    height: 21px;
    float: left;
    margin-top: 20px;
}

.icons {
    position: absolute;
    right: 50px;
}

.icons a {
    height:40px;
    width:40px;
    float:left;
    display:inline-block;
}

.center-form {
    max-width: 800px;
    margin: 0 auto;
    margin-top:50px;
}

.wrapper {
    padding:0px 75px;
    margin-top:40px;
}

.wrapper h1 {
    font-size: 20px;
    font-weight: 400;
    border-bottom: 1px solid #BB8D49;
    line-height:100px;
}

.no-border {
    border-bottom: none !important;
    width:200px;

}
.border-bottom {
    border-bottom: 1px solid #BB8D49;
    margin-bottom: 40px;
}

.center {
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
}

.right {
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    text-align: right;
}

.float-right {
    float: right;
}

.left {
    text-align:left;
}

.float-left {
    float:left;
}

.border-right {
    border-right: 1px solid #BB8D49;
    padding-right:20px;
    margin-right:20px;
}

.wrapper-main {
    max-width:none !important;
    margin-top:50px;
}

.right-box {
    margin-left:20px;
}

.right-box span {
    float:left;    
}

button {
    padding:0px 20px !important;
}

.wrapper-main h2 {
    background: #BB8D49 0% 0% no-repeat padding-box;
    height:20px;
    font-size:16px;
    color:#fff;
    width:100%;
}

.fullWidth {
    width:100%;
    text-align:center;
}

.left-align {
    text-align:left;
}

.no-block {
    display: flex;
}

.paper-dialog {
    min-width:500px;
}



.MuiDialog-paperWidthXl {
    width:750px;
}

.clear {
    clear:both;
}

.validation-error {
    color:#fe0000;
    width:100%;
}

.validation-green {
    color: #178903;
    width: 100%;
}

.MuiOutlinedInput-root {
    margin-left:-2px;
    height:52px;
}

.MuiFormControl-root {
    
}

input[type=date] {
    padding-right:10px;
}


.MuiTablePagination-toolbar p:nth-child(2), .MuiTablePagination-selectRoot {
    display: none !important;
}

.filter .MuiTextField-root, .filter-no-background .MuiTextField-root {
    border: none !important;
    margin-top: -17px;
}

.MuiInputBase-input {
    padding-right:18px !important;
}

.max-imagewidth
{
    max-width:95%;
    float:left;
}

.right-box p, .left-align p {
    text-align: left;
    font-size: 16px;
    color: #B4B4B4;
}

.right-box strong{
    color: #BB8D49 !important;
    font-size: 16px;
}

.left-align strong {
    font-size:16px;
    color: #000;
}

.ml-10 {
    margin-left:20px;
}

.ml-discount-10 {
    margin-left: -10px;
}

.ml-5 {
    margin-left: 10px;
}

.question {
    background: #B9B9B9;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
    float: left;
    text-align: center;
    margin-left: 20px;
    padding-top: 5px;
    font-weight: 700;
    margin-top: 10px;
}

.selected {
    background: #BB8D49;
}

.border-top {
    padding-top:20px;
    border-top: 1px solid #B9B9B9;
}

.padding {
    padding: 10px;
}

.MuiPaper-rounded {
    border-radius:10px;
}

.courses-list button.MuiButtonBase-root {
    height:35px;
}

.mt10 {
    margin-top:20px !important;
}

.mt10a {
    margin-top: 10px;
}

.ml10 {
    margin-left: 10px;
}

.ml5 {
    margin-left: 5px;
}


img.mt10 {
    max-width: 150px;
}

.course-user-list img.mt10 {
    max-width:140px !important;
}

.course-show img.mt10 {
    max-width: 80% !important;
}

.inline-buybutton {
    margin-left:-10px;
    display:inline;
}

.button {
    background: #bc9049 0% 0% no-repeat padding-box;
    border-radius: 5px !important;
    box-shadow: 0px 4px 10px #00000026;
    color: #fff !important;
    height: 35px;
    text-decoration: none;
    display: inline-block;
    line-height: 35px;
    padding: 0 10px;
    font-weight: 700;
    cursor: pointer;
}

.width100{
    width:100%;

}

.courses-list {
    margin-top:-30px;
}

.courses-list .MuiPaper-root {
    height: 240px;
    overflow-y: hidden;
}

.courses-list .MuiPaper-root p:nth-child(3),.courses-list .MuiPaper-root p:nth-child(2) {
    max-height: 40px;
    overflow-y: hidden;
}

    .MuiTextField-root {
        width: 100%;
    }

.multiline .MuiTextField-root {
    height:auto !important;
}

.MuiInputBase-inputMultiline {
    height: 150px !important;
}

.searchInput {
    width:300px;
    font-size:14px;
}

.filter-no-background .searchInput {
    width: 100px;
}

.users-filter .searchInput{
    width:150px;
}

.right-box table td {
    vertical-align: inherit !important;
}

.bread {
    text-decoration: none;
    font-size: 16px;
    color: #000;
}

.description-avatar img {
    max-width: 40px;
    border-radius: 50%;
    margin-top: 12px;
    margin-left: 10px;
}

.description-avatar p {
    
}

.right-box .description-avatar p {
    margin:10px !important;
    font-size:14px;
}

.black-font {
    color: #000 !important;
}

.right-box .blue-font2 {
    margin-top:17px !important;
}

.add-course {
    float: right;
    line-height: 15px;
}

.only-mobile {
    display: none;
}

@media (max-width: 1300px) {

    .course-user-list img.mt10 {
        max-width:80% !important;
    }

    .clear {
        margin-top: 50px;
    }

    .courses-list .clear {
        margin-top: 0px;
    }

    .not-mobile {
        display: none;
    }

    .only-mobile {
        display:block;
    }

    .login-grid .MuiGrid-grid-xs-3 {
        max-width: 100%;
        flex-basis: 100%;
    }

    .MuiGrid-grid-xs-6 {
        max-width: 100% !important;
        flex-basis: 100% !important;
    }

    .idetails {
        display:none;
    }

    .sort-select {
        display:none !important;
    }

    .search {
        display:none;
    }

    .wrapper {
        padding: 0px 20px;
    }

    .avatar {
        position: absolute;
        left: 20px;
        top: 70px;
        display:none;
        right:auto;
    }

    .menu {
        position: absolute;
        margin-left: 0px;
        top: 110px;
        left: -35px;
    }

    .logo-small {
        background-repeat: no-repeat;
        margin-left: 5px;
        margin-top: 30px;
    }

    .icons {
        top:0px;
        right:0px;
    }

    .wrapper h1 {
        
    }

    .border-bottom {
        border-bottom: none;
        margin-bottom: 0px;
        margin-left:-33px;
        margin-top:15px;
    }

    .no-border {
        margin-left:39px;
    }

    .course-show .MuiGrid-grid-xs-5 {
        max-width: 100% !important;
        flex-basis: 100% !important;
    }

    button {
        padding:0px;
    }

    button.MuiButton-textPrimary {
        font-size:10px;
        height:30px;
    }

    .question{
        margin-left:10px;
    }

    .searchInput {
        width: 60px;
        font-size: 14px;
    }

    .cert-filter {
        margin-top:-100px !important;
        margin-left:10px !important;
    }

    .add-user {
        position:absolute;
        left:-3px;
        margin-top:100px;
    }

    .paper-dialog .MuiGrid-grid-xs-8 {
        max-width: 100%;
        flex-basis: 100%;
    }

    .radio .width25 {
        margin-top: 20px;
    }

    .fullWidth-mobile {
        max-width: 100% !important;
        flex-basis: 100% !important;
    }

    .fullWidth-50mobile {
        max-width: 45% !important;
        flex-basis: 45% !important;
    }

    .fullWidth-55mobile {
        max-width: 55% !important;
        flex-basis: 55% !important;
    }


    .padding-mobile {
        padding: 0 10%;
    }

    .fullWidth-mobile p {
        width:100% !important;
    }

    .margin-zero-table {
    }
    
    .ml-discount-10 {
        margin-left: 0px;
    }

    .inline-buybutton {
        margin-left:0;
    }

    .brown-font {
        font-size:10px;
    }

    .fullWidth-50mobile .button {
        width:96%;
    }
}

.width200 {
    width:200px;
    float:left;
}

.width400 {
    width: 400px;
    display:block;
    margin: 0 auto;
}

.border-right .bread {
    float:left;
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid #BB8D49 !important;
    border-radius: 5px;
    height: 58px;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(8px, -8px) scale(0.75);
}

.course-show .wrapper h1 {
    font-size: 20px;
    font-weight: 400;
    border-bottom: 1px solid #BB8D49;
    line-height: 20px;
    padding-bottom:20px;
}

.course-show .max-imagewidth {
    max-width:100%;
}

.course-show .border-right p {
    font-size:14px;
}

.course-show .gray-font {
    font-size:14px;
    display:block;
    width:80px;
    text-align:left;
    margin-left:0px;
}

.course-show .MuiFormControlLabel-root {
    float:left;
    font-size:14px;
}

.course-show .MuiFormControlLabel-label {
    font-size:14px;
}

.course-show .MuiFormGroup-root {
    flex-wrap: nowrap;
    flex-direction: unset;
}

.ml-2 {
    margin-left:20px;
}

.course-show .border-right {
    border-right: 1px solid #B9B9B9;
}

.MuiInputBase-multiline {
    padding-right:10px !important;
}

.MuiInputBase-inputMultiline {
    overflow:auto !important;
}

a {
    cursor:pointer;
}

.sort-select {
    margin-top:35px !important;
}

.more-font {
    font-size:16px;
}

.green-radio span {
    color: #178903 !important;
}

.red-radio span {
    color: #FA2B2B !important;
}


h5 {
    font-size:16px;
}

.preshow {
    border: solid 1px #bc9049;
}

.preshow p {
    font-size: 14px;
    line-height: 15px;
    margin: 0px 10px;
    margin-left:0px;
    margin-right:0px;
    text-align:left;
}

.padding-8 {
   padding-right:10px;
}

.preshow .button {
    font-size: 18px;
    background: #fff;
    color: #bc9049 !important;
    border: 1px solid #bc9049;
}

.mt-5 {
    margin-top:5px;
}

.red-font {
    color: #fe0000 !important;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    display: block;
    margin: 0 auto;
    text-align: center;
}

.red-font-buy {
    color: #fe0000 !important;
    text-decoration: none;
    font-weight: 600;
}

.black-font {
    color:#000;
    font-size:16px;
}

.sort-font {
    color: #B9B9B9 !important;
    font-size: 16px;
}

.mt17 {
    margin-top:16px;
}

.mt17 .gold-font {
    font-size:16px;    
}

p {
    white-space: pre-line;
}

.width25 {
    width:25%;
}

.radio .width25{
    width:100%;
    display:block;
}

.radio .MuiFormControlLabel-root {
    float:none;
}

table td {
    vertical-align:top;
}

.actionColumn {
    width:125px;
}

.white-background {
    background: #f5f5f5;
}

.form-control {
    background: none !important;
    border: 1px solid #BB8D49 !important;
    height: 53px !important;
    max-width: 100%;
}

.react-tel-input .flag-dropdown {
    border: 1px solid #BB8D49 !important;
}

.no-margin-left {
    margin-left:0;
}

.no-margin-top {
    margin-top: 0;
}

.margin-zero-table {
    margin-left:-20px;
}

.svgLoader2 {
    margin-left:10px;
    margin-top:10px;
}